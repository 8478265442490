define("discourse/plugins/MrBug-TroikiPoisk/discourse/controllers/megaadd", ["exports", "ember", "jquery"], function (_exports, _ember, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Controller.extend({
    addstuff: {
      "GAME": null,
      "STRING": null,
      "ADDFB": false
    },
    p4lista: null,
    actions: {
      oops() {
        _jquery.default.post("/admin/MegaAdd/", {
          GAME: this.get('addstuff.GAME'),
          STRING: this.get('addstuff.STRING'),
          ADDFB: this.get('addstuff.ADDFB')
        }).then(result => {
          this.set('addstuff', result);
          this.set('addstuff.ADDFB', false);
        });
      },
      Reset() {
        this.set('addstuff', {
          "GAME": null,
          "STRING": null,
          "ADDFB": false
        });
      },
      FeedbacksGo() {
        this.set('addstuff.ADDFB', true);
      },
      FeedbacksStop() {
        this.set('addstuff.ADDFB', false);
      },
      P4Lista() {
        return fetch('/MrBug.json').then(function (response) {
          return response.json();
        }).then(result => {
          this.set('p4lista', result.gamelist);
        });
      }
    }
  });
});