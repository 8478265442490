define("discourse/plugins/MrBug-TroikiPoisk/discourse/templates/connectors/user-profile-controls/ebanidze", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li><LinkTo @route='user.kek' class='btn'>{{d-icon 'star-half-alt'}}Отзывы</LinkTo></li>
  
  */
  {
    "id": "HSmrD92G",
    "block": "[[[10,\"li\"],[12],[8,[39,1],[[24,0,\"btn\"]],[[\"@route\"],[\"user.kek\"]],[[\"default\"],[[[[1,[28,[35,2],[\"star-half-alt\"],null]],[1,\"Отзывы\"]],[]]]]],[13],[1,\"\\n\"]],[],false,[\"li\",\"link-to\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/MrBug-TroikiPoisk/discourse/templates/connectors/user-profile-controls/ebanidze.hbs",
    "isStrictMode": false
  });
});