define("discourse/plugins/MrBug-TroikiPoisk/discourse/MrBug-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('mrbug', {
      path: '/MrBug'
    });
    this.route('megaadd', {
      path: '/admin/MegaAdd'
    });
    this.route('rentagama', {
      path: '/renta-haleguu'
    });
    this.route('user', function () {
      this.route('kek');
    });
  }
  ;
});