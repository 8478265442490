define("discourse/plugins/MrBug-TroikiPoisk/discourse/routes/mrbug", ["exports", "discourse/lib/ajax", "ember"], function (_exports, _ajax, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Route.extend({
    model() {
      return (0, _ajax.ajax)('/MrBug.json');
    }
  });
});