define("discourse/plugins/MrBug-TroikiPoisk/discourse/templates/connectors/user-main-nav/ebanidze", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route='user.kek'>{{d-icon 'star-half-alt'}}Отзывы</LinkTo>
  
  */
  {
    "id": "b6G2b5Fh",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"user.kek\"]],[[\"default\"],[[[[1,[28,[35,1],[\"star-half-alt\"],null]],[1,\"Отзывы\"]],[]]]]],[1,\"\\n\"]],[],false,[\"link-to\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/MrBug-TroikiPoisk/discourse/templates/connectors/user-main-nav/ebanidze.hbs",
    "isStrictMode": false
  });
});